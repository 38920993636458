#root {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.nav {
  width: 100%;
  height: 50px;
  background-color: rgba(80,80,80, 0.3);
  box-shadow: 0px 0px 10px white inset;
  margin-bottom: 10px;
}
.App {
  width: 800px;
  margin: 0 auto;
}
.opeartor {
  display: flex;
  height: 31px;
  align-items: center;
}
.App-inner {
  display: flex;
  position: relative;
  max-height: 790px;
}
.time-line {
  width: 180px;
  height: 600px;
  overflow: scroll;
  padding-top: 20px;
}
.time-line-word-item {
  cursor: pointer;
  border-bottom: 2px dashed #1677ff;
  display: inline-block;
  line-height: 40px;
}
.word-content {
  width: 620px;
  margin-right: 20px;
  margin-top: 12px;
}
.empty-tip-box {
  border: 2px dashed #1677ff;
  border-radius: 8px; 
}
.empty-tip-text {
  font-size: 22px;
}
.word-container{
  font-size: 12px;
  color: #1d2a57;
  overflow: hidden;
  padding: 10px 16px 16px;
  background-color: #efefef;
  border-radius: 8px;
  border: 1px solid #dddddd;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.word {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 6px;
}
.word-text {
  font-size: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.word-class {
  font-size: 14px;
  font-weight: 700;
  font-style: italic;
  line-height: 20px;
  margin-top: 10px;
}
.word-audio-and-sound-mark {
  display: flex;
  font-size: 14px;
  border-bottom: 1px solid #dddddd;
  height: 36px;
}
.word-sound {
  display: flex;
  align-items: center;
}
.uk {
  margin-right: 10px;
}
.word-sound-type {
  margin-right: 6px;
  font-size: 12px;
  font-weight: 800;
}
.sound-mark {
  margin: 10px;
}
.word-sense-container {
  margin-top: 5px;
}
.sense {
  display: flex;
}
.sense-img {
  width: 200px;
  height: 133px;
  margin-right: 20px;
}
.img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
}
.sense-info {
  flex-grow:1; 
}
.word-level {
  margin-left: 10px;
  padding: 2px 6px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  background-color: #1d2956;
  border-radius: 50px;
  height: 14px;
  margin-top: 10px;
}
.word-def {
  margin-top: 6px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 6px;
  border: 1px solid #fff;
  background-color: #fff;
  padding: 8px 12px;
  display: flex;
  align-items: center;
}
.def-audio {
  margin-right: 8px;
}
.word-example-list {
  background-color: #fff;
  padding: 0 12px;
  border-radius: 6px;
  border: 1px solid #fff;
  margin-bottom: 16px;
  position: relative;
  height: 430px;
  overflow: scroll;
}
.word-example-list:last-child {
  margin-bottom: 0;
}
.word-example-item {
  margin: 12px 0;
  font-size: 16px;
  line-height: 28px;
  background-color: #F0FFFF;
  padding: 12px;
}
.example-audio {
  margin-right: 8px;
}
.dic-example-audio {
  margin-right: 8px;
}
.word-example-item:last-child {
  border-bottom: none;
}
.word-example-item:nth-child(2n) {
  background-color: #fff8e4;
}
.word-example-item-left {
  width: 300px;
  height: 300px;
  background: url("http://139.180.129.169/images/loading.gif") center center no-repeat;
  background-size: 25%;
  margin: 0 auto;
}
.word-example-item-right {
  display: flex;
  width: 100%;
  font-size: 18px;
  margin: 10px auto 0;
}
.current {
  background-color: rgb(255, 192, 203, .5) !important;
  font-size: 18px !important;
  font-weight: bold;
}
.sense2 {
  padding-top: 10px;
  border-top: 2px solid #ffc400;
  margin-top: 16px;
}
.class-and-level {
  display: flex;
  align-items: center;
  height: 30px;
}
.time-line-word-item-container {
  display: flex;
  align-items: center;
}
.time-line-word-item-dot {
  width: 5px;
  height: 5px;
  background-color: #fff;
  border-radius: 6px;
  display: inline-block;
  border: 3px solid #1677ff;
  margin-right: 8px;
}
.time-line-word-item-red {
  border-bottom: 2px dashed orangered;
  color: orangered;
  font-weight: bold;
}
.time-line-word-item-dot-red {
  border: 3px solid orangered;
}
.quick-menu {
  height: 600px;
  width: 70px;
  padding-top: 20px;
  overflow: scroll;
}
.css-dev-only-do-not-override-dkbvqv {
  max-height: 600px !important;
  overflow: scroll;
}
.all-words-info {
  margin-bottom: 20px;
}
.all-words-info-title {
  font-size: 14px;
  font-weight: bold;
  span {
    color: orangered;
  }
}
  