
.review-container {
    width: 700px;
    margin: 0 auto;
    position: relative;
}
.review-word-info {
  font-size: 12px;
  color: #1d2a57;
  overflow: hidden;
  padding: 10px 16px 16px;
  background-color: #efefef;
  border-radius: 8px;
  border: 1px solid #dddddd;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.review-word {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 6px;
  margin-bottom: 10px;
}
.review-word-text {
  font-size: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.review-word-example-list {
  height: auto;
}

.review-pre-arrow {
  cursor: pointer;
  position: absolute;
  top: 300px;
  left: -60px;
  width: 50px;
  height: 100px;
  font-size: xxx-large;
}
.review-next-arrow {
  cursor: pointer;
  position: absolute;
  top: 300px;
  right: -60px;
  width: 50px;
  height: 100px;
  font-size: xxx-large;
}
.review-pre-arrow-disabled {
  color: gray;
  cursor: not-allowed;
}
.review-next-arrow-disabled {
  color: gray;
  cursor: not-allowed;
}
  